import React from 'react';

const Loader = () => {
  return (
    <section className="main-loader" >
      <div className="loader">
        <span className="load load1"></span>
        <span className="load load2"></span>
        <span className="load load3"></span>
      </div>
      <h4>Loading</h4>
    </section>
  );
};

export default Loader;
