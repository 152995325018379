import React, { useEffect } from "react";

const CircleShape = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'circle') {
      let circle, isDown;
      let mouse;
      editor.off('mouse:down');
      editor.off('mouse:move');
      editor.off('mouse:up');
      editor.on('mouse:down', function (o) {
        o.e.stopPropagation();
        if (editor.findTarget(o.e)) return;
        isDown = true;
        mouse = editor.getPointer(o.e);

        circle = new global.fabric.Circle({
          top: mouse.y,
          left: mouse.x,
          radius: mouse.x - mouse.x,
          stroke: color,
          fill: '#ffffff00',
          strokeWidth: 6,
          selectable: true,
          eventable: true,
          hasControls: true,
          hasBorders: false,
          shadow: new global.fabric.Shadow({
            blur: 2,
            offsetX: 0,
            offsetY: 0,
            affectStroke: true,
            color: 'rgba(0,0,0,0.8)',
          }),
          cornerColor: 'blue',
          cornerSize: 10,
          padding: -3,
          cornerStrokeColor: 'white',
          transparentCorners: false,
          cornerStyle: 'circle',
          hasRotatingPoint: false,
          borderScaleFactor: 2,
          objectCaching: false,
          strokeUniform: true
        });

        circle.setControlsVisibility({ml: false, mt: false, mb: false, mr: false});
        editor.add(circle);
        editor.renderAll();
      });

      editor.on('mouse:move', function (o) {
        o.e.stopPropagation();
        if (!isDown) return false;
        let pointer = editor.getPointer(o.e);

        if (mouse.x > pointer.x) {
          circle.set('originX', 'right');
        } else {
          circle.set('originX', 'left');
        }

        if (mouse.y > pointer.y) {
          circle.set('originY', 'bottom');
        } else {
          circle.set('originY', 'top');
        }
        let radius = Math.abs(mouse.y - pointer.y)/2;
        if (radius > circle.strokeWidth) {
          radius -= circle.strokeWidth/2;
        }
        circle.set('radius', radius);
        // editor.setActiveObject(circle);
        circle.setCoords();
        editor.renderAll();
      });

      editor.on('mouse:up', function (o) {
        o.e.stopPropagation();
        if (isDown) {
          isDown = false;
        }
      });
    }
  }, [editor, color, activeTool]);
  return (
    <li className={`circle ${activeTool === 'circle' ? 'active' : ''}`} data-tooltip="Circle">
      <i>
        <svg width="22" height="22" viewBox="0 0 22 22">
          <path
            fill="#FFF"
            d="M11 21.406c5.887 0 10.656-4.77 10.656-10.656C21.656 4.863 16.886.094 11 .094 5.113.094.344 4.864.344 10.75c0 5.887 4.77 10.656 10.656 10.656zm0-2.062c-4.748 0-8.594-3.846-8.594-8.594 0-4.748 3.846-8.594 8.594-8.594 4.748 0 8.594 3.846 8.594 8.594 0 4.748-3.846 8.594-8.594 8.594z"
          />
        </svg>
      </i>
    </li>
  )
}

export default CircleShape;
