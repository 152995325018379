import React, { useState, useRef, useEffect } from 'react';

const FileName = ({ hasEditAccess, fileName, fullStorageKey, setFileName}) => {
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const updateFileName = async () => {
    let newFileName = inputRef.current.value;
    console.log('New FileName', newFileName);
    setIsEditing(false);
    if (newFileName.length > 0) {
      setFileName(newFileName);
      let result = await fetch(`${global.location.origin}/update-file-name?fullStorageKey=${fullStorageKey}&fileName=${newFileName}`);
      if (result.ok) {
        result = await result.json();
      }
      console.log('result :', result);
    } else {
      console.warn('trying to set empty string as fileName is blocked');
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      return updateFileName();
    } else if (event.keyCode === 27) {
      return setIsEditing(false);
    }
  }

  useEffect(() => {
    if (inputRef && inputRef.current && isEditing === true) {
      inputRef.current.value = fileName;
      inputRef.current.focus();
    }
  })

  return (
    <>
      <div className="file-name">
        {
          (isEditing) ? (
            <input
              ref={inputRef}
              type="text"
              placeholder="file name"
              onBlur={updateFileName}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
          ) : <p>{fileName}</p>
        }
      </div>
      {
        (hasEditAccess && !isEditing) ? (
          <button className="nostyle edit-btn " onClick={() => setIsEditing(true)}>
            <svg width="12" height="12" viewBox="0 0 12 12">
              <g fill="none">
                <path fill="#353535" d="M10.345,4.656 L11.67,3.33 C12.11,2.89 12.11,2.178 11.67,1.739 L10.261,0.329 C9.822,-0.11 9.109,-0.11 8.67,0.329 L7.344,1.655 L10.344,4.655 L10.345,4.656 Z M0.624,11.996 L3.299,11.701 L9.815,5.186 L6.815,2.186 L0.301,8.698 L0.003,11.375 C-0.037,11.733 0.266,12.036 0.624,11.996 Z" />
                <path fill="#C9C9C9" d="M10.345,4.656 L11.67,3.33 C12.11,2.89 12.11,2.178 11.67,1.739 L10.261,0.329 C9.822,-0.11 9.109,-0.11 8.67,0.329 L7.344,1.655 L10.344,4.655 L10.345,4.656 Z M0.624,11.996 L3.299,11.701 L9.815,5.186 L6.815,2.186 L0.301,8.698 L0.003,11.375 C-0.037,11.733 0.266,12.036 0.624,11.996 Z" />
              </g>
            </svg>
          </button>
        ) : null
      }
    </>
  );
}

export default FileName;
