import React from 'react';
import { useEffect } from 'react';

function redoOption(props) {
  let {
    editor,
    objects,
    setIsRedoing,
    setObjects
  } = props;
  if (objects.length > 0) {
    setIsRedoing(true);
    editor.add(objects.pop());
    setObjects(objects);
  }
}

export default function RedoIcon(props) {

  useEffect(() => {
    const redo = (e) => {
      if ((e.keyCode === 90 && e.metaKey && e.shiftKey) || (e.keyCode === 89 && e.ctrlKey)) {
          redoOption(props);
      }
    }
    document.addEventListener('keydown', redo);
    return () => document.removeEventListener('keydown', redo);
  }, [props]);

  return (
    <li onClick={(e) => { redoOption(props) }}>
      <i className="step redo">
        <svg width="14" height="12" viewBox="0 0 14 12">
          <path fill="#4C4C4C" d="M2.447 11.72C1.294 10.88.25 9.274.25 7.654c0-4.014 3.358-4.863 7.594-4.911V.634c0-.546.64-.83 1.046-.48l4.64 4.008c.293.252.293.705 0 .958L8.89 9.127c-.406.35-1.046.067-1.046-.479V6.33c-4.09.06-5.852 1.074-4.656 4.898.132.424-.38.753-.74.491z" opacity=".5" />
        </svg>
      </i>
    </li>
  );
}
