import React, { useEffect } from "react";

const PixelateOption = ({ editor, activeTool }) => {
  useEffect(() => {
    if (editor && activeTool === 'pixelate') {
      let isMouseDown = false;
      editor.off('mouse:up');
      editor.off('mouse:down');
      editor.off('mouse:move');
      editor.on('mouse:down', async (event) => {
        event.e.stopPropagation();
        if (event.target && event.target._metaType === "pixelateObject") {
          return;
        }
        isMouseDown = true;
        editor.discardActiveObject();
        editor.renderAll();
        const positionInfo = {
          start: {},
          stop: {}
        };
        positionInfo.start = {
          ...event.absolutePointer
        };
        positionInfo.stop = {
          ...event.absolutePointer
        };
        let pxObj = await addPixelateObject(editor, positionInfo);
        editor.on('mouse:move', function (event) {
          if (!isMouseDown) return;
          event.e.stopPropagation();
          positionInfo.stop = {
            ...event.absolutePointer
          };
          pxObj.set(computeOptions(positionInfo));
          editor.renderAll();
        });
        editor.on('mouse:up', (event) => {
          event.e.stopPropagation();
          positionInfo.stop = {
            ...event.absolutePointer
          };
          pxObj.set(computeOptions(positionInfo));
          editor.renderAll();
          editor.off('mouse:up');
          editor.off('mouse:move');
          isMouseDown = false;
        });
      });
    };
  }, [editor, activeTool]);
  return (
    <li className={`pixelate ${activeTool === 'pixelate' ? 'active' : ''}`} data-tooltip="Pixelate">
      <i>
        <svg width="48" height="48" viewBox="0 0 48 48">
          <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <path fill="#000" d="M14.375 15h-4.75c-.621 0-1.125.504-1.125 1.125v3.75c0 .621.504 1.125 1.125 1.125h4.75c.621 0 1.125-.504 1.125-1.125v-3.75c0-.621-.504-1.125-1.125-1.125z" opacity=".5" transform="translate(12 14)" />
              <path fill="#000" d="M22.875 7.5h-4.75C17.504 7.5 17 8.004 17 8.625v3.75c0 .621.504 1.125 1.125 1.125h4.75c.621 0 1.125-.504 1.125-1.125v-3.75c0-.621-.504-1.125-1.125-1.125z" opacity=".45" transform="translate(12 14)" />
              <path fill="#000" d="M14.375 0h-4.75C9.004 0 8.5.504 8.5 1.125v3.75C8.5 5.496 9.004 6 9.625 6h4.75c.621 0 1.125-.504 1.125-1.125v-3.75C15.5.504 14.996 0 14.375 0z" opacity=".403" transform="translate(12 14)" />
              <path fill="#FFF" d="M5.875 7.5h-4.75C.504 7.5 0 8.004 0 8.625v3.75c0 .621.504 1.125 1.125 1.125h4.75C6.496 13.5 7 12.996 7 12.375v-3.75C7 8.004 6.496 7.5 5.875 7.5zM22.875 15h-4.75c-.621 0-1.125.504-1.125 1.125v3.75c0 .621.504 1.125 1.125 1.125h4.75c.621 0 1.125-.504 1.125-1.125v-3.75c0-.621-.504-1.125-1.125-1.125z" opacity=".3" transform="translate(12 14)" />
              <path fill="#FFF" d="M14.375 7.5h-4.75c-.621 0-1.125.504-1.125 1.125v3.75c0 .621.504 1.125 1.125 1.125h4.75c.621 0 1.125-.504 1.125-1.125v-3.75c0-.621-.504-1.125-1.125-1.125z" transform="translate(12 14)" />
              <path fill="#FFF" d="M22.875 0h-4.75C17.504 0 17 .504 17 1.125v3.75C17 5.496 17.504 6 18.125 6h4.75C23.496 6 24 5.496 24 4.875v-3.75C24 .504 23.496 0 22.875 0z" opacity=".8" transform="translate(12 14)" />
              <path fill="#FFF" d="M5.875 0h-4.75C.504 0 0 .504 0 1.125v3.75C0 5.496.504 6 1.125 6h4.75C6.496 6 7 5.496 7 4.875v-3.75C7 .504 6.496 0 5.875 0z" opacity=".7" transform="translate(12 14)" />
              <path fill="#FFF" d="M5.875 15h-4.75C.504 15 0 15.504 0 16.125v3.75C0 20.496.504 21 1.125 21h4.75C6.496 21 7 20.496 7 19.875v-3.75C7 15.504 6.496 15 5.875 15z" opacity=".2" transform="translate(12 14)" />
            </g>
          </g>
        </svg>
      </i>
    </li>
  )
}

function addPixelateObject(editor, positionInfo) {
  return new Promise((resolve) => {
    const imageSrc = editor.toDataURL();
    global.fabric.Image.fromURL(imageSrc, (img) => {
      img._metaType = 'pixelateObject';
      img.set({
        opacity: 0.9,
        ...computeOptions(positionInfo)
      });
      img.hasControls = false;
      img.filters.push(new global.fabric.Image.filters.Pixelate({ blocksize: 10 }));
      img.applyFilters();
      editor.add(img).renderAll();
      resolve(img);
    },
      { crossOrigin: 'Anonymous' }
    );
  });
}

function computeOptions(positionInfo) {
  let left = positionInfo.start.x;
  let top = positionInfo.start.y;
  let width = positionInfo.stop.x - positionInfo.start.x;
  let height = positionInfo.stop.y - positionInfo.start.y;
  switch (Math.sign(width)) {
    case 0:
      width = 1;
      break;
    case -1:
      left = positionInfo.stop.x;
      width = positionInfo.start.x - positionInfo.stop.x;
      break;
    default:
  }
  switch (Math.sign(height)) {
    case 0:
      height = 1;
      break;
    case -1:
      top = positionInfo.stop.y;
      height = positionInfo.start.y - positionInfo.stop.y;
      break;
    default:
  }
  return {
    top,
    left,
    width,
    height,
    cropX: left,
    cropY: top
  };
}

export default PixelateOption;
