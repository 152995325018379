import React from 'react';

const AnnotateButton = ({ editMode, setEditMode, hasEditAccess}) => {
  return (
      (hasEditAccess && !editMode) ? (
        <li>
          <button className="button button-secondary" onClick={() => setEditMode(true)}>Annotate</button>
        </li>
      ) : null
  )
};

export default AnnotateButton;
