import React, { useState } from 'react';

const CopyLinkButton = () => {

  const [copied, setCopied] = useState(false);

  const copyCurrentLink = (event) => {
    event.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(global.location.href).then(() => {
        console.log('successfully copied the link!');
        setCopied(true);
        setTimeout(() => {
          setCopied(false)
        }, 500);
      }).catch(err => {
        console.error(err);
      })
    }
  };

  return (
    <li>
      {
        (!copied) ? (
          <button className="button" onClick={copyCurrentLink}>Copy Link</button>
        ) : (
            <button className="button" style={{ backgroundColor: '#5986e0' }}>Copied</button>
          )
      }
    </li>
  );
};

export default CopyLinkButton;
