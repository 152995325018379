import React, { useEffect } from 'react';

function undoOption(props) {
  let objects = props.objects;
  if (props.editor._objects.length > 0) {
    console.log(props.editor._objects);
    let editObject = props.editor._objects;
    if (editObject[editObject.length - 1].width <= 0 && editObject[editObject.length - 1].height <= 0) {
      props.editor._objects.pop();
    }
    objects.push(props.editor._objects.pop());
    props.setObjects(objects);
    props.editor.renderAll();
  }
}

export default function UndoIcon(props) {

  useEffect(() => {
    const undo = (e) => {
      if ((e.keyCode === 90 && e.metaKey && !e.shiftKey) || (e.keyCode === 90 && e.ctrlKey)) {
        undoOption(props);
      }
    };
    document.addEventListener('keydown', undo);
    return () => document.removeEventListener('keydown', undo);
  }, [props]);

  return (
    <li>
      <i className="step" onClick={() => { undoOption(props) }}>
        <svg width="14" height="12" viewBox="0 0 14 12">
          <path fill="#4C4C4C" d="M11.553 11.72c1.153-.84 2.197-2.445 2.197-4.065 0-4.014-3.358-4.863-7.594-4.911V.634c0-.546-.64-.83-1.046-.48L.47 4.162c-.293.252-.293.705 0 .958l4.64 4.007c.406.35 1.046.067 1.046-.479V6.33c4.09.06 5.852 1.074 4.656 4.898-.132.424.38.753.74.491z" opacity=".5" />
        </svg>
      </i>
    </li>
  );
}
