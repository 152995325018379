import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/:fullStorageKey">
          <App isDev={false} />
        </Route>
        { (process.env.NODE_ENV === 'development') ? <App isDev={true} /> : null }
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
