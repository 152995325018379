import React, { useEffect } from "react";

const ErasedGroup = global.fabric.util.createClass(global.fabric.Group, {
  original: null,
  erasedPath: null,

  initialize: function (original, erasedPath, options, isAlreadyGrouped) {
    this.original = original;
    this.erasedPath = erasedPath;
    this.callSuper('initialize', [this.original, this.erasedPath], options, isAlreadyGrouped);
  },

  _calcBounds: function (onlyWidthHeight) {
    const aX = [];
    const aY = [];
    const props = ['tr', 'br', 'bl', 'tl'];
    const jLen = props.length;
    const ignoreZoom = true;
    let o = this.original;
    o.setCoords(ignoreZoom);

    for (let j = 0; j < jLen; j++) {
      const prop = props[j];
      aX.push(o.oCoords[prop].x);
      aY.push(o.oCoords[prop].y);
    }

    this._getBounds(aX, aY, onlyWidthHeight);
  },
});

const EraserBrush = global.fabric.util.createClass(global.fabric.PencilBrush, {

  _finalizeAndAddPath: function () {
    const ctx = this.canvas.contextTop;
    ctx.closePath();
    if (this.decimate) {
      this._points = this.decimatePoints(this._points, this.decimate);
    }
    const pathData = this.convertPointsToSVGPath(this._points).join('');

    if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
      this.canvas.requestRenderAll();
      return;
    }

    const path = this.createPath(pathData);
    path.globalCompositeOperation = 'destination-out';
    path.selectable = false;
    path.evented = false;
    path.absolutePositioned = true;

    const objects = this.canvas.getObjects().filter((obj) => {
      if (obj instanceof global.fabric.Textbox) return false;
      if (!obj.intersectsWithObject(path)) return false;
      return true;
    });

    if (objects.length > 0) {
      const mergedGroup = new global.fabric.Group(objects);
      const newPath = new ErasedGroup(mergedGroup, path);
      const left = newPath.left;
      const top = newPath.top;
      const newData = newPath.toDataURL({
        withoutTransform: true
      });
      global.fabric.Image.fromURL(newData, (fabricImage) => {
        fabricImage.set({
          left: left,
          top: top,
        });
        this.canvas.remove(...objects);
        this.canvas.add(fabricImage);
      });
    }
    this.canvas.clearContext(this.canvas.contextTop);
    this.canvas.renderAll();
    this._resetShadow();
  },
});

const EraserOption = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'eraser') {
      editor.freeDrawingBrush = new EraserBrush(editor);
      editor.freeDrawingBrush.width = '6';
      editor.freeDrawingBrush.color = '#ffffff';
    }
  }, [editor, color, activeTool]);
  return (
    <li className={`eraser ${activeTool === 'eraser' ? 'active' : ''}`} data-tooltip="Eraser">
      <i>
        <svg width="19" height="18" viewBox="0 0 19 18">
          <path fill="#FFF" d="M18.555 17.187c.245 0 .445-.199.445-.445v-.89c0-.246-.2-.446-.445-.446h-5.942l5.865-5.865c.288-.288.522-.852.522-1.26 0-.407-.234-.971-.522-1.26l-5.937-5.937c-.288-.288-.853-.521-1.26-.521-.407 0-.971.233-1.26.521l-9.5 9.5c-.287.288-.52.852-.52 1.26 0 .407.233.971.52 1.26l3.563 3.562c.288.288.852.521 1.26.521h13.21zm-5.159-5.083L7.46 6.166l3.822-3.822L17.22 8.28l-3.823 3.823zm-3.302 3.302h-4.75L1.78 11.844l4.416-4.416 5.938 5.937-2.041 2.041z" />
        </svg>
      </i>
    </li>
  )
}

export default EraserOption;
