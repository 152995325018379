import React from 'react';

const UserProfile = ({ photoId, isMobile, fullStorageKey }) => {
  const getLoginPage = async (event) => {
    event.stopPropagation();
    let result = await fetch(`${global.location.origin}/login?fullStorageKey=${fullStorageKey}`);
    let { data } = await result.json();
    if (data) {
      window.location.assign(data);
    }
  };
  return (
    (!isMobile) ? (
      (photoId) ? (
        <li className="profile-pic">
          {
            (photoId.length > 1) ? (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={photoId} />
            ) : (
                <span>{photoId}</span>
              )
          }
        </li>
      ) : (
          <li>
            <button className="button" onClick={getLoginPage}>Login</button>
          </li>
        )
    ) : null
  );
};

export default UserProfile;
